const apiProd = "https://doctoriteprod.zyppys.in/" //PRODUCTION

// const api = "https://uathealth.zyppys.in/" //TEST

// var api = "https://uathealth.zyppys.in/"
if (process.env.NODE_ENV === "production") {
  var api = "https://doctoriteprod.zyppys.in/"
  var doctoriteAccessClinicId = 21
} else {
  var doctoriteAccessClinicId = 10
  var api = "https://uathealth.zyppys.in/"
}
export const doctoriteId = doctoriteAccessClinicId

// Fetch Lookups
export const fetchLookupByCategoryURL = api + "fetchLookupByCategory"

//---------- Doctor URLs

export const fetchDoctorsURL = api + "fetchDoctors"
export const fetchDoctorByIdURL = api + "fetchDoctorById"
export const insertOrUpdateDoctorURL = api + "insertOrUpdateDoctor"
export const enableDoctor = api + "enableDoctor"
export const disableDoctor = api + "disableDoctor"
export const approveOrDisapproveDoctor = api + "approveOrDisapproveDoctor"

//---------- Health Care Professional URLs

export const fetchHealthCareProfessionalsURL =
  api + "fetchHealthCareProfessionals"
export const fetchHealthCareProfessionalByIdURL =
  api + "fetchHealthCareProfessionalById"
export const insertOrUpdateHealthCareProfessionalURL =
  api + "insertOrUpdateHealthCareProfessional"
export const enableHealthCareProfessionalURL =
  api + "enableHealthCareProfessional"
export const disableHealthCareProfessionalURL =
  api + "disableHealthCareProfessional"
export const approveOrDisapproveHealthCareProfessionalURL =
  api + "approveOrDisapproveHealthCareProfessional"

//---------- Patient URLs

export const fetchPatientsURL = api + "fetchPatients"
export const fetchPatientByIdURL = api + "fetchPatientById"
export const insertOrUpdatePatientURL = api + "insertOrUpdatePatient"
export const fetchSecondaryPatientsURL = api + "fetchProfileDetails"

//---------- Appointment URLs

export const fetchAppointmentsURL = api + "fetchAppointments"
export const updateAppointmentStatusURL = api + "updateAppointmentStatus"

//---------- Organisation URLs

export const fetchOrganisationsURL = api + "fetchOrganizations"
export const addNewOrganisationURL = api + "insertOrUpdateOrganization"

//---------- User Login

export const userLoginURL = api + "loginPortal"

//---------- Clinic

export const insetOrUpdateClinicURL = api + "insertOrUpdateClinic"
export const fetchClinicsURL = api + "fetchClinics"

//---------- Wellness

export const fetchWellnessVideosURL = api + "fetchVideos";
export const updateWellnessVideoURL = api + "insertOrUpdateVideo";
export const fetchVideoCategoriesURL = api + "fetchLookupByCategory";

//---------- Stats

export const getAppointmentsCountStatsURL = api + "getAppointmentsCountStats";
export const getRequestCountStatsURL = api + "getRequestCountStats";
export const getVideoViewStatsURL = api + "getVideoViewStats";
export const fetchListenerHistoryURL = api + "getListnerTimeDurationForStats";
export const fetchMoodHistoryURL = api + "getMoodScoreForStat";

//------- Counsellor

export const getListeners = api + "getAllListeners";
export const fetchListenerTimeTracker = api + "listeners";
export const fetchListenerStatus = api + "updateListenerStatus";

//----------Analytics
export const fetchPatientAnalyticsListenerRequestsStats = api + "fetchPatientAnalyticsListenerRequestsStats"
export const fetchPatientAnalyticsListenerRequestsStatsDetail = api + "fetchPatientAnalyticsListenerRequestsStatsDetail"
export const fetchListnerAnalyticsListenerRequestsStats = api + "fetchListnerAnalyticsListenerRequestsStats"
export const fetchListnerAnalyticsListenerRequestsStatsDetail = api + "fetchListnerAnalyticsListenerRequestsStatsDetail"
export const fetchListnerAppointmentDetail = api + "fetchListnerAppointmentDetail"
export const fetchListnerAppointmentStats = api + "fetchListnerAppointmentStats"
