import styled, { createGlobalStyle } from "styled-components"

const fonts = {
  dFont: "'Roboto', -apple-system, 'HelveticaNeue', sans-serif",
  hFont: "lato",
  pFont: "Roboto",
}

export const GlobalStyle = createGlobalStyle`
* {
box-sizing: border-box;
margin: 0;
padding: 0; 
font-family: ${fonts.dFont};
}
.rdrDefinedRangesWrapper {
  display: none;
}
main{
  grid-area: main;
  padding: 0rem;
  /* width: 100vw; */
  width: calc(100vw - 12rem);
  height: 100vh ;
  justify-content: flex-start;
  margin: 0rem auto;
  overflow-y: scroll;
  /* font-family: ${fonts.dFont}; */
   @media (max-width: 500px) {
    width: 100%;
  padding: 0;
  overflow-y: inherit;
  }
}
a {
    text-decoration: none;
    color: black;
}
p{
  font-family: ${fonts.pFont};
}
h1,h2,h3,h4,h5 {
  font-family: ${fonts.hFont};
  text-transform: uppercase;
  word-spacing: .5em;

} 
`
export const DashboardLayout = styled.div`
  display: grid;
  grid-template-areas: "menu main";
  grid-gap: 0rem;
  /* grid-template-columns: "12rem 1fr"; */

  @media (max-width: 500px) {
    grid-template-areas:
      "menu"
      "main";
  }
`

export const Section = styled.section`
  width: 100%;
  padding: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

export const TableStyles = styled.div`
  padding-right: 0rem;
  margin-top: 1rem;
  overflow: scroll;
  max-height: 80vh;
  /* width: fit-content; */
  /* width: 100%; */

  input {
    height: 2rem;
    /* width: 50%; */
    border: 2px solid lightgray;
    padding: 0.3rem 0.5rem;
    margin: 0.7rem 0.2rem;
    font-size: 0.9rem;
    ::placeholder {
      font-weight: lighter;
    }
    @media (max-width: 500px) {
      width: 99%;
    }
  }
  table {
    /* height: 50vh; */
    width: 100%;
    overflow-x: scroll;
    margin-top: 1rem;
    font-size: 0.8rem;
    border-spacing: 0;
    border: none;
    /* border-bottom: 1px solid lightgray; */
    thead {
      color: white;
      background-color: #4299e1;
      th {
        font-weight: normal;
        letter-spacing: 1px;
        padding: 0.5rem 0.5rem;
        border-right: 1px solid white;
      }
    }
    tbody {
      cursor: pointer;

      tr {
        color: darkslategray;

        /* border-bottom: 1px solid lightgray; */
        :nth-child(even) {
          background-color: #edf7fa;
        }
        :hover {
          background-color: whitesmoke;
        }
      }
    }
    tr {
      :last-child {
        td {
          /* border-bottom: 0; */
          border-bottom: 1px solid lightgray;
        }
      }
    }
    th,
    td {
      line-height: 1.2rem;
      margin: 0;
      max-width: 14rem;
      padding: 0.5rem 0.6rem 0.6rem 0.6rem;
      border: none;
      /* border-bottom: 1px solid lightgray; */
      /* border-right: 1px solid lightgray; */
      /* :first-child {
        width: 9rem;
      } */
      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    /* border-bottom: 1px solid lightgray; */

    /* padding: 1.5rem; */
    /* padding-bottom: 0; */
    /* width: fit-content; */

    align-self: center;
    /* margin: 1rem; */
    text-align: center;
    /* justify-content: center; */
    /* overflow: scroll; */
    align-items: center;
    span {
      font-size: 0.8rem;
      margin: 0.6rem;
      font-weight: lighter;

      strong {
        font-weight: lighter;
      }
    }
    button {
      cursor: pointer;
      margin: 0.6rem;
      padding: 0.3rem 0.5rem;
      color: black;
      background-color: whitesmoke;
      border: 1px solid lightgray;
      border-radius: 10px;
    }
    input[type="number"] {
      font-size: 0.8rem;
      margin: 0.6rem;
      width: 3rem;
      height: 1.5rem;
      padding: 0.2rem;
      border-radius: 5px;
    }
    select {
      margin: 0.6rem;
      background-color: whitesmoke;
      border: 1px solid lightgray;
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      border: none;
      /* margin: 0 1rem; */
      width: 7rem;
      height: 2rem;
      padding: 0.5rem;
      option {
        background-color: whitesmoke;
      }
    }
    @media (max-width: 500px) {
      width: max-content;
      /* justify-content: center; */
      margin: 1rem auto;
      /* align-self: center; */
    }
  }
  @media (max-width: 500px) {
    width: 100%;
    max-height: 80vh;
  }
`

export const Form = styled.form`
  width: 100%;
  margin: 3rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr 8rem;
  grid-gap: 0rem;

  p {
    color: black;
    font-size: 0.95rem;
    margin-top: 0.5rem;
    line-height: 1.3rem;
  }
  /* div {
    display: flex;
    flex-wrap: wrap;
  } */
  label {
    height: fit-content;
    width: 11rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    margin-right: 1.2rem;
    color: gray;
    margin-bottom: 1.5rem;
    div {
      color: black;
    }
  }

  select {
    font-size: 0.8rem;
    height: 2rem;
    box-sizing: border-box;
    border: none;
    background-color: white;
  }
  input,
  textarea {
    font-size: 0.9rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid lightsteelblue;
    height: 2.5rem;
    ::placeholder {
      color: gray;
      font-size: 0.9rem;
    }
  }
  textarea {
    padding-top: 0.7rem;
  }
  button {
    height: 2.5rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0rem 1rem;
    margin: 2rem 0;
    font-size: 0.9rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  canvas {
    height: 30rem;
    width: 30rem;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    label {
      width: 49%;
      margin-right: 0.1rem;
    }
  }
`
export const AddForm = styled(Form)`
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"] {
    width: 100%;
    font-size: 1rem;
  }
  label {
    margin-right: 3rem;
    ::placeholder {
      padding-bottom: 1rem;
      color: black;
    }
  }
`
export const LoginForm = styled(Form)`
  font-family: ${fonts.dFont};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 2rem 1rem 0 1rem;
  align-items: center;
  height: fit-content;
  width: fit-content;
  label {
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
    color: gray;
    margin: auto 1rem;
    margin-bottom: 2rem;
    padding-bottom: 0rem;
  }
  input[type="email"],
  input[type="password"] {
    width: 100%;
    font-size: 1rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-top: 0.5rem;
    padding-left: 0.5rem;
    border: 1.5px solid #7c0ae2;
    border-radius: 5px;
    ::placeholder {
      font-weight: lighter;
    }
  }
  button {
    font-size: 1rem;
    margin: auto 0;
    height: 5.5rem;
    padding: 0.5rem 2rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${fonts.hFont};
    text-transform: uppercase;
    word-spacing: 0.5em;
  }

`
export const PostsForm = styled(Form)`
  grid-template-columns: none;
 display: flex;
  flex-direction: row;
    flex-wrap: wrap;
    width:fit-content;
  label {
    /* height: fit-content; */
    width: 6rem;
    display: flex;
    flex-direction: column;
    /* margin: 0.5rem 0; */
    margin-bottom: 0.5em;
    font-size: 0.6rem;
    margin-right: 0.5rem;
    color: gray;
    /* margin-bottom: 1.5rem; */
    div {
      color: black;
    }
    p {
      padding-top: 0.5rem;
      font-size: 0.7rem;
      width: 6rem;
    }
  }
`;
export const SelectStyles = {
  // height: "3rem",
  control: (_, { selectProps: { width, height } }) => ({
    width: width,
    height: "2.5rem",
    fontSize: "1rem",
    border: "1px solid lightgray",
    cursor: "pointer",
  }),
  menu: (_, { selectProps: { width, height } }) => ({
    width: width,
    fontSize: "1rem",
    border: "1px solid lightgray",
    color: "black",
    backgroundColor: "whitesmoke",
    cursor: "pointer",
  }),
  valueContainer: (provided, state) => {
    const height = "2.5rem"
    const padding = "0 5px 0 5px"
    return { ...provided, height, padding }
  },
}

export const FAQs = styled(Section)`
  display: block;
  h1 {
    font-family: ${fonts.hFont};
    padding-bottom: 3rem;
    word-spacing: 0.5rem;
    text-transform: uppercase;
  }
  h4,
  h2,
  h3 {
    font-family: ${fonts.hFont};
    word-spacing: 0.1em;
    padding-bottom: 1em;
    text-transform: uppercase;
  }
  p {
    word-spacing: 0.1em;
    line-height: 1.5em;
  }
  div {
    margin: 2rem 0;
  }
`
export const Termsandc = styled(FAQs)`
  p {
    margin: 2rem 0;
  }
  h3 {
    padding-bottom: 0em;
  }
  h5 {
    font-size: 1em;
  }
`
